const Loader = () => {
  return (
    <div className="w-full gap-x-2 flex justify-center items-center">
      <div className="w-4 bg-[#d991c2] animate-pulse h-4 rounded-full animate-bounce"></div>
      <div className="w-4 animate-pulse h-4 bg-[#9869b8] rounded-full animate-bounce"></div>
      <div className="w-4 h-4 animate-pulse bg-[#6756cc] rounded-full animate-bounce"></div>
    </div>
  );
};

export default Loader;
