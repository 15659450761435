import React, { useState, useEffect } from "react";

const AddButton = ({
  collections,
  fontPair,
  setCollections,
  saveCollectionsToBackend,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [newCollection, setNewCollection] = useState("");
  const [currentElement, setCurrentElement] = useState(null);
  const toggleDropdown = (e) => {
    setCurrentElement(e.target);
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (currentElement && !currentElement.contains(event.target)) {
        if (!event.target.closest(".input")) {
          setIsOpen(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [currentElement]);

  const handleAddCollection = (string) => {
    if (newCollection.trim()) {
      setCollections([
        ...collections,
        { collectionid: crypto.randomUUID(), name: string, fontPairs: [] },
      ]);
      setNewCollection("");
    }
  };

  const handleAddFontPairsToCollection = (el, fonts) => {
    setCollections((prevCollections) =>
      prevCollections.map((collection) =>
        el.collectionid === collection.collectionid
          ? {
              ...collection,
              fontPairs: [
                ...collection.fontPairs,
                {
                  fontPairid: crypto.randomUUID(),
                  fontValues: fonts,
                },
              ],
            }
          : collection
      )
    );
  };

  return (
    <div className="dropdown-container relative flex">
      <button id={crypto.randomUUID()} onClick={toggleDropdown}>
        Add To Collections
      </button>

      {isOpen && (
        <div className="fixed container inset-0 h-screen max-w-full bg-black bg-opacity-50 z-40">
          <div className="fixed w-80 p-3 bg-white border-black border-2 rounded-md shadow-lg z-10">
            <div className="input flex flex-col gap-2 justify-center items-center px-4 py-2">
              <input
                type="text"
                value={newCollection}
                onChange={(e) => setNewCollection(e.target.value)}
                placeholder="New Collection"
                className="w-full px-2 py-2 border border-gray-300 rounded-md"
              />
              <button
                onClick={() => {
                  handleAddCollection(newCollection);
                }}
                className="mt-2 w1/3 py-2  text-white rounded-md"
              >
                Add Collection
              </button>
            </div>

            <div className="border-t border-gray-300 my-2"></div>

            <ul className="py-1">
              {collections && collections.length > 0
                ? collections.map((el) => (
                    <li
                      key={el.id}
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100 cursor-pointer"
                      onClick={() => {
                        handleAddFontPairsToCollection(el, fontPair);
                      }}
                    >
                      {el.name}
                    </li>
                  ))
                : "empty"}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddButton;
