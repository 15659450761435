import { CopyToClipboard } from "react-copy-to-clipboard";
import { IoMdClose } from "react-icons/io";
import { RiDeleteBin6Line } from "react-icons/ri";

function CollectionOverlay({ collection, onClose, deletePalette }) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
      <div className="bg-white p-14 rounded shadow-lg w-auto relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 flex items-center justify-center h-10 w-10 text-black bg-transparent rounded-full p-0 border-solid"
        >
          <IoMdClose />
        </button>
        <h2 className="text-2xl top-2 font-bold mb-4 poppins-bold">
          {collection.name} - Collection
        </h2>
        <div className="space-y-4">
          {collection.colorPalettes.map((palette, index) => {
            const colorCodes = palette.colorValues[0].join(", ");
            return (
              <div
                key={index}
                className="flex items-center space-x-4 p-4 bg-white rounded-lg shadow-md"
              >
                <div className="flex w-auto space-x-2 items-center">
                  {palette.colorValues[0].map((color, idx) => (
                    <div
                      key={idx}
                      className="w-12 h-12 relative transition-all duration-300 ease-in-out flex items-center justify-center bg-gray-300 hover:w-24 hover:h-12"
                      style={{ backgroundColor: color.replace(/'/g, "") }}
                    >
                      <span className="absolute inset-0 flex items-center justify-center text-white font-medium transition-opacity duration-300 opacity-0 hover:opacity-100">
                        {color}
                      </span>
                    </div>
                  ))}
                  <div className="flex justify-center items-center ml-8">
                    <button
                      onClick={() => deletePalette(palette.id)}
                      className="bg-red-500 text-white text-lg p-3 rounded border-none hover:bg-red-600"
                    >
                      <RiDeleteBin6Line />
                    </button>
                    <CopyToClipboard text={colorCodes}>
                      <button className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-500 border-none">
                        Copy Codes
                      </button>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
export default CollectionOverlay;
