import React, { useEffect, useState } from "react";
import OpenCollection from "./OpenCollection";
import AddButton from "./AddButton";

const FontPairings = ({
  fontPairings,
  setFontPairings,
  setSubmitted,
  collections,
  setCollections,
}) => {
  const [currKey, setCurrKey] = useState("1");
  const [randomNumber, setRandomNumber] = useState(1);
  const [isFading, setIsFading] = useState(false);

  let backendURL;
  useEffect(() => {
    backendURL =
      process.env.REACT_APP_ENV === "production"
        ? process.env.REACT_APP_BACKEND
        : process.env.REACT_APP_BACKEND_LOCAL;
  });

  const textContent = {
    1: [
      "A Serene Retreat",
      "A Peaceful Escape into Nature's Embrace",
      `The sunbeams danced through the canopy, casting a mesmerizing play
            of light and shadow on the forest floor. A gentle breeze, carrying
            the sweet fragrance of pine needles and damp earth, caressed the
            skin. A winding path, worn smooth by countless footsteps, beckoned
            the weary traveler deeper into the woods. As the sun began its
            descent, painting the sky in hues of orange and purple, the forest
            transformed into a magical realm. The tranquility of the place was
            broken only by the soft rustle of leaves and the distant call of a
            bird.`,
    ],
    2: [
      "The Alien Encounter",
      "A Close Encounter of the Third Kind",
      "A lone astronomer, gazing at the cosmos through a powerful telescope, witnessed an extraordinary sight. A brilliant, unidentified object streaked across the night sky, leaving a trail of iridescent light. As the object drew closer to Earth, it transformed into a colossal spacecraft, hovering silently above a secluded desert. A hatch opened, and a group of extraterrestrial beings emerged, their forms shrouded in shimmering suits. The astronomer, awestruck and terrified, watched as the aliens extended a welcoming hand, inviting humanity to join them on a cosmic journey.",
    ],
    3: [
      "The Celestial Ballet",
      "A Night Under the Starry Sky",
      `As darkness enveloped the world, the sky transformed into a celestial canvas. A myriad of stars twinkled above, their light painting the night sky with celestial beauty. A gentle breeze carried the sweet scent of night-blooming flowers, while the distant hoot of an owl added to the tranquility. A lone figure sat beneath the starlit sky, lost in thought, as the universe unfolded its cosmic ballet.`,
    ],
    4: [
      "The Whispering Woods",
      "A Mystical Encounter in the Forest",
      `The sun filtered through the dense canopy, casting dappled shadows on the forest floor. A winding path led deeper into the woods, where ancient trees stood tall and proud. A soft breeze rustled the leaves, creating a soothing symphony. As the sun began its descent, the forest transformed into a mystical realm, filled with enchanting sounds and ethereal sights. A curious creature, half-hidden in the undergrowth, watched the visitor with knowing eyes, its presence a reminder of the magic that lurked within the woods.`,
    ],
    5: [
      "The Forgotten City",
      "A Journey Through Time",
      `A veil of mist shrouded the ancient city, its ruins half-hidden by time. A lone explorer, armed with a map and a sense of adventure, ventured into the forgotten city. The air was thick with the scent of decay and mystery, and the silence was broken only by the soft whisper of the wind. As the explorer wandered through the crumbling streets, they stumbled upon a hidden chamber, its walls adorned with intricate carvings and ancient symbols.`,
    ],
    6: [
      "The Ocean's Depths",
      "A Dive into the Unknown",
      `The sun's rays shimmered on the ocean's surface, inviting the curious diver to explore the depths below. Armed with a mask and snorkel, they plunged into the cool, crystalline water. A vibrant underwater world unfolded before their eyes, teeming with life. Schools of colorful fish darted through the coral reefs, while majestic sea turtles glided effortlessly through the water. The diver, mesmerized by the beauty of the marine ecosystem, felt a sense of peace and wonder.`,
    ],
    7: [
      "The Desert Mirage",
      "A Quest for Water in the Sands of Time",
      `The relentless sun beat down on the vast expanse of sand, creating a shimmering mirage on the horizon. A weary traveler, parched and dehydrated, pressed on, driven by the hope of finding water. The silence of the desert was broken only by the howling wind and the occasional cry of a distant bird. As the sun began to set, casting long shadows across the dunes, the traveler stumbled upon a hidden oasis, a lush oasis of life in the heart of the desert.`,
    ],
    8: [
      "The Mountain's Peak",
      "A Breathtaking View from the Summit",
      `The mountain, a majestic giant, stood tall against the sky. A determined climber, armed with ropes and ice axes, began their ascent. The higher they climbed, the thinner the air became, and the colder the temperature. The climber, undeterred, pressed on, driven by the allure of the summit. As they reached the peak, a breathtaking panorama unfolded before their eyes. The world, a patchwork of valleys, forests, and lakes, stretched out beneath them, a testament to the power and beauty of nature.`,
    ],
    9: [
      "The Haunted House",
      "A Night of Frights and Chills",
      `The old, creaking house stood at the end of the street, its windows dark and ominous. A group of friends, drawn by curiosity and a love for the macabre, decided to explore the abandoned house. As they ventured deeper into the house, strange noises echoed through the halls, and cold drafts swept through the rooms. A sudden gust of wind slammed a door shut, plunging the house into darkness. The friends, their hearts pounding with fear, huddled together, unsure of what horrors lurked in the shadows.`,
    ],
    10: [
      " The Time Traveler's Dilemma",
      "A Journey Through Time and Space",
      `A brilliant scientist, obsessed with the mysteries of time travel, had finally succeeded in creating a time machine. With a touch of a button, they were transported to a distant future, a world of advanced technology and unimaginable wonders. However, a malfunction in the time machine threatened to trap them in the future forever. Desperate to return to their own time, the scientist must race against time to fix the machine and escape the clutches of the future.`,
    ],
  };

  function generateRandomNumber() {
    // Generate a random number between 0 (inclusive) and 10 (exclusive)
    const randomNumber = Math.random() * 10;

    // Round down to the nearest integer to get a number from 0 to 9
    const roundedNumber = Math.floor(randomNumber);

    // Add 1 to get a number from 1 to 10
    return roundedNumber + 1;
  }
  const saveCollectionsToBackend = async (collections) => {
    try {
      const response = await fetch(`${backendURL}/generate-fonts/save`, {
        credentials: "include",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ collections }),
      });
      if (!response.ok) {
        throw new Error("Failed to save collections");
      }
    } catch (error) {
      console.error("Error saving collections to backend:", error);
    }
  };

  useEffect(() => {
    if (collections.length > 0) {
      try {
        saveCollectionsToBackend(collections);
      } catch (error) {
        console.log(error);
      }
    }
  }, [collections]);

  const handleChange = (key) => {
    setIsFading(true); // Start fade-out
    setTimeout(() => {
      setCurrKey(key);
      setRandomNumber(generateRandomNumber());
      setIsFading(false); // Start fade-in
    }, 300); // Match transition duration
  };

  return (
    <div className="flex flex-col h-screen">
      <div className="flex flex-row w-full h-1/4">
        <div className="w-1/6 bg-black"></div>
        <div className="flex pl-20">
          <div className="flex flex-row gap-10 items-center">
            {Object.keys(fontPairings).map((key) => (
              <button
                className={`${
                  key === currKey
                    ? "bg-black text-white"
                    : "bg-transparent text-black"
                }  border-solid rounded-full py-2 px-4 ml-0 hover:text-white `}
                key={key}
                onClick={() => handleChange(key)}
              >
                {key}
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className={`flex flex-col text-left h-2/4`}>
        <div className="flex flex-row w-full h-1/4">
          <div className="w-1/6  bg-black text-right pr-10">
            <span
              className={`text-xl font-bold text-white transition-opacity duration-300 ${
                isFading ? "opacity-0" : "opacity-100"
              }`}
            >
              {fontPairings[currKey][0]}
            </span>
          </div>
          <h1
            className={`text-6xl font-bold pl-20 transition-opacity duration-300 ${
              isFading ? "opacity-0" : "opacity-100"
            }`}
            contenteditable="true"
            style={{ fontFamily: fontPairings[currKey][0] }}
          >
            {textContent[randomNumber][0]}
          </h1>
        </div>
        <div className="flex flex-row w-full h-1/4">
          <div className="text-right pr-10 bg-black w-1/6">
            <span
              className={`text-xl font-bold text-white transition-opacity duration-300 ${
                isFading ? "opacity-0" : "opacity-100"
              }`}
            >
              {fontPairings[currKey][1]}
            </span>
          </div>
          <h3
            className={`text-3xl font-semibold pl-20 outline-none transition-opacity duration-300 ${
              isFading ? "opacity-0" : "opacity-100"
            }`}
            contenteditable="true"
            style={{ fontFamily: fontPairings[currKey][1] }}
          >
            {textContent[randomNumber][1]}
          </h3>
        </div>
        <div className="flex flex-row w-full h-2/4">
          <div className="text-right pr-10 bg-black w-1/6">
            <span
              className={`text-xl font-bold text-white transition-opacity duration-300 ${
                isFading ? "opacity-0" : "opacity-100"
              }`}
            >
              {fontPairings[currKey][2]}
            </span>
          </div>
          <p
            className={`text-xl font-normal w-3/6 pl-20 outline-none transition-opacity duration-300 ${
              isFading ? "opacity-0" : "opacity-100"
            }`}
            contenteditable="true"
            style={{ fontFamily: fontPairings[currKey][2] }}
          >
            {textContent[randomNumber][2]}
          </p>
        </div>
      </div>
      <div className="flex flex-row w-full h-1/4">
        <div className="text-center bg-black w-1/6"></div>
        <div className="flex items-center pl-20">
          <button
            className="ml-0"
            onClick={() => {
              setSubmitted(false);
            }}
          >
            Go Back
          </button>
          {/* <button
            onClick={() => {
              saveCollectionsToBackend(collections);
            }}
          >
            Save To Backend
          </button> */}
          <OpenCollection
            collections={collections}
            setCollections={setCollections}
            setFontPairings={setFontPairings}
            saveCollectionsToBackend={saveCollectionsToBackend}
          />
          <AddButton
            collections={collections}
            setCollections={setCollections}
            fontPair={fontPairings[currKey]}
            saveCollectionsToBackend={saveCollectionsToBackend}
          />
        </div>
      </div>
    </div>
  );
};

export default FontPairings;
