import { useState, useEffect } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BsArrowDownRightSquare } from "react-icons/bs";

const OpenCollection = ({
  collections,
  saveCollectionsToBackend,
  setCollections,
  setFontPairings,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredCollectionId, setHoveredCollectionId] = useState(null); // To track the hovered collection

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (isOpen && !event.target.closest("#drawer")) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  const deleteCollection = (id) => {
    setCollections(
      collections.filter((collection) => collection.collectionid !== id)
    );
  };
  const loadCollection = (collection) => {
    const newFontPairings = {};
    collection.fontPairs.map((pair, index) => {
      newFontPairings[index + 1] = pair.fontValues;
    });
    setFontPairings(newFontPairings);
  };

  return (
    <div>
      <button onClick={toggleDrawer}>See Collections</button>

      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>
      )}

      <div
        id="drawer"
        className={`fixed top-0 left-0 w-64 h-full bg-white shadow-lg z-50 transform transition-transform duration-300 ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div className="mt-4 px-4">
          {collections.length > 0 ? (
            collections.map((collection) => (
              <div
                key={collection.id}
                className="p-2 mb-4 bg-gray-100 rounded-md shadow-md relative"
                onMouseEnter={() => setHoveredCollectionId(collection.id)}
                onMouseLeave={() => setHoveredCollectionId(null)}
              >
                <p className="poppins-bold">{collection.name}</p>

                {/* Show buttons on hover */}
                {hoveredCollectionId === collection.id && (
                  <div className="absolute top-2 right-2 space-x-2">
                    <button
                      onClick={() => deleteCollection(collection.collectionid)}
                      className="bg-red-500 text-white p-1 rounded hover:bg-red-600 m-0 border-none"
                    >
                      <RiDeleteBin6Line />
                    </button>
                    <button
                      onClick={() => loadCollection(collection)}
                      className="bg-green-500 text-white p-1 rounded hover:bg-green-600 m-0 border-none"
                    >
                      <BsArrowDownRightSquare />
                    </button>
                  </div>
                )}
              </div>
            ))
          ) : (
            <p>No Collections Here</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default OpenCollection;
