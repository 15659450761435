const CollectionHistory = ({ collections, setSubmitted, setFontPairings }) => {
  const loadCollection = (collection) => {
    const newFontPairings = {};
    if (collection.fontPairs.length === 0) {
      alert("Empty Collection");
      return;
    }
    collection.fontPairs.map((pair, index) => {
      newFontPairings[index + 1] = pair.fontValues;
    });
    setFontPairings(newFontPairings);
    setSubmitted(true);
  };
  return (
    <div className="mt-6 rest-card rounded-lg shadow-lg p-8 mx-auto max-w-lg">
      <h2 className="text-xl font-bold text-gray-900 mb-4 poppins-bold">
        Collections
      </h2>
      {collections.length > 0 ? (
        <ul className="space-y-2 poppins-regular">
          {collections &&
            collections.length > 0 &&
            collections.map((collection, index) => (
              <li
                key={index}
                className="cursor-pointer text-indigo-600 hover:underline"
                onClick={() => loadCollection(collection)}
              >
                {collection.name}
              </li>
            ))}
        </ul>
      ) : (
        <h3>No Collections</h3>
      )}
    </div>
  );
};

export default CollectionHistory;
