import React, { useEffect, useRef, useState } from "react";
import CircularProgressBar from "./CircularProgressBar";
import ThreeDotsLoader from "./ThreeDotsLoader";
import "./DesignAnalysis.css";

import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

function DesignAnalysis({
  data,
  currentIndex,
  setCurrentIndex,
  handleNext,
  handlePrevious,
  buttons,
  setButtons,
  setSubmitted,
  setHistory,
  historyRef,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(data.imageUrl || "");
  const [designScores, setDesignScores] = useState({});
  const [designAnalysis, setDesignAnalysis] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [currNumber, setCurrNumber] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  let backendURL;
  useEffect(() => {
    backendURL =
      process.env.REACT_APP_ENV === "production"
        ? process.env.REACT_APP_BACKEND
        : process.env.REACT_APP_BACKEND_LOCAL;
  });

  const initializeDesignData = (data) => {
    setImageUrl(data.image || "");

    setDesignScores({
      Layout: data.Score.Layout,
      Typography: data.Score.Typography,
      ColorHarmony: data.Score.ColorHarmony,
      Alignment: data.Score.Alignment,
      VisualImpact: data.Score.VisualImpact,
    });

    setDesignAnalysis({
      Layout: data["Detailed Feedback"].Layout,
      Typography: data["Detailed Feedback"].Typography,
      ColorHarmony: data["Detailed Feedback"].ColorHarmony,
      Alignment: data["Detailed Feedback"].Alignment,
      VisualImpact: data["Detailed Feedback"].VisualImpact,
    });
  };

  const handleNextScore = () => {
    setCurrNumber((prev) => (prev === 4 ? 0 : prev + 1));
  };
  const handlePreviousScore = () => {
    setCurrNumber((prev) => (prev === 0 ? 4 : prev - 1));
  };

  try {
    useEffect(() => initializeDesignData(data), [data]);
  } catch (error) {
    console.log(error);
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    // if (file) {
    //   setImageUrl(URL.createObjectURL(file));
    // }
  };

  const handleFollowUp = async (e) => {
    e.preventDefault();
    setButtons({
      previous: true,
      next: true,
      upload: true,
    });
    setIsLoading(true);
    const formData = new FormData();
    const { followUpNumber, idForFollowUp } = data;
    formData.append("followUpNumber", followUpNumber);
    formData.append("id", idForFollowUp);
    if (!selectedFile) {
      console.error("Please select a design file to upload.");
      alert("Please Upload a file to continue");
      setIsLoading(false);
      setTimeout(() => {
        setButtons({
          previous: false,
          next: true,
          upload: false,
        });
      }, 3000);
      return;
    }
    formData.append("design", selectedFile);

    try {
      const response = await fetch(`${backendURL}/critique/follow-up`, {
        credentials: "include",
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Error uploading design: ${response.statusText}`);
      }

      setCurrentIndex((prev) => prev + 1);
      setSelectedFile(null);

      const data = await response.json();
      console.log(data);

      setIsLoading(false);
      initializeDesignData(data.newData);

      try {
        const response = await fetch(`${backendURL}/critique/history`, {
          credentials: "include",
          method: "GET",
          // body: formData,
        });

        const data = await response.json();
        setHistory(data.history);
        historyRef.current = data.history;
      } catch (error) {
        console.log(error);
      }
      setTimeout(() => {
        setButtons({
          previous: false,
          next: true,
          upload: false,
        });
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  };
  const size = 150;
  const stroke = 10;
  return (
    <div className="analysis-container">
      <h2 className="title poppins-bold">Design Analysis</h2>
      <div className="image-and-scores gap-16">
        <div className="image-section">
          <img
            src={imageUrl}
            alt="Uploaded Design"
            className="uploaded-image"
          />
        </div>
        <div className="scores relative">
          <button
            className="absolute top-0 left-0 z-10"
            onClick={() => {
              handlePreviousScore();
            }}
          >
            <FaArrowLeft />
          </button>
          <button
            className="absolute top-0 right-0 z-10"
            onClick={() => {
              handleNextScore();
            }}
          >
            <FaArrowRight />
          </button>
          <div className="individual-score">
            <span className="absolute top-0 poppins-bold text-2xl pt-5">
              Design Insights
            </span>
            <div className="score flex flex-col items-center gap-5 p-4 pt-16">
              <CircularProgressBar
                progress={designScores[Object.keys(designAnalysis)[currNumber]]}
                size={size}
                strokeWidth={stroke}
              />
              <span className="text-lg poppins-bold">
                {Object.keys(designAnalysis)[currNumber] === "ColorHarmony"
                  ? "Color Harmony"
                  : Object.keys(designAnalysis)[currNumber] === "VisualImpact"
                  ? "Visual Impact"
                  : Object.keys(designAnalysis)[currNumber]}
              </span>
            </div>
            <span className="score-text poppins-regular pb-4">
              {designAnalysis[Object.keys(designAnalysis)[currNumber]]}
            </span>
          </div>
        </div>
      </div>

      <div className="buttons">
        <button
          onClick={() => {
            handlePrevious(currentIndex, data.idForFollowUp);
          }}
          disabled={buttons.previous}
          className="btn"
        >
          Previous
        </button>
        <button
          onClick={() => {
            handleNext(currentIndex, data.idForFollowUp);
          }}
          disabled={buttons.next}
          className="btn"
        >
          Next
        </button>
      </div>
      <div className="h-fit pb-10">
        <form onSubmit={handleFollowUp}>
          <div className="form flex flex-col justify-center items-center p-5">
            <h2 className="text-3xl poppins-bold p-4">
              Upload Follow-Up Design
            </h2>
            <div className="p-4 pb-0">
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                className="block border border-gray-300 rounded-md p-3"
              />
              <div className="flex justify-evenly items-center">
                <button type="submit" className="btn" disabled={buttons.upload}>
                  {isLoading ? <ThreeDotsLoader /> : "Upload"}
                </button>
                <button
                  type="button"
                  className="new-btn"
                  onClick={() => {
                    setSubmitted(false);
                  }}
                >
                  New Design
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default DesignAnalysis;
