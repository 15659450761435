import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../userContext";
import { RiDeleteBin6Line } from "react-icons/ri";

//user has a design

const DesignHistory = ({
  SetDataFromBackend,
  setSubmitted,
  setCurrentIndex,
  history,
  setHistory,
  setButtons,
}) => {
  // const [user, setUser] = useContext(UserContext);
  const [currProjectId, setCurrProjectId] = useState(null);

  let backendURL;
  useEffect(() => {
    backendURL =
      process.env.REACT_APP_ENV === "production"
        ? process.env.REACT_APP_BACKEND
        : process.env.REACT_APP_BACKEND_LOCAL;
  });

  const fetchHistory = async () => {
    try {
      const response = await fetch(`${backendURL}/critique/history`, {
        credentials: "include",
        method: "GET",
        // body: formData,
      });
      const data = await response.json();

      setHistory(data.history);
    } catch (error) {
      console.log(error);
    }
  };
  const loadProject = (project) => {
    let newData;
    setSubmitted(true);
    if (project.followUp.length === 0) {
      newData = project.analysis;
    } else {
      newData = project.followUp.slice(-1)[0].analysis;
    }
    newData.idForFollowUp = project._id;
    newData.followUpNumber = 2;
    SetDataFromBackend(newData);
    setCurrentIndex(project.followUp.length);
    if (project.followUp.length === 0) {
      setButtons({
        previous: true,
        next: true,
        upload: false,
      });
    }
  };

  useEffect(() => {
    fetchHistory();
  }, []);

  const handleDelete = async (project) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      // Perform the delete action
      try {
        const response = await fetch(`${backendURL}/critique/delete`, {
          credentials: "include",
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Inform the backend that you're sending JSON
          },
          body: JSON.stringify(project), // Convert the project object to a JSON string
        });

        const data = await response.json(); // Parse JSON response from the backend
      } catch (error) {
        console.error(error);
      }
    } else {
      return;
    }
  };

  return (
    <div className="mt-6 rest-card rounded-lg shadow-lg p-8 mx-auto max-w-lg text-center">
      <h2 className="text-xl poppins-bold mb-4">Project History</h2>
      {history.length > 0 ? (
        <ul className="space-y-2">
          {history &&
            history.length > 0 &&
            history.map((project, index) => (
              <div
                className=" flex items-center gap-2"
                onMouseEnter={() => {
                  setCurrProjectId(project._id);
                }}
                onMouseLeave={() => {
                  setCurrProjectId(null);
                }}
              >
                <li
                  key={index}
                  className="cursor-pointer text-indigo-600 hover:underline list-none"
                  onClick={() => loadProject(project)}
                >
                  {project.projectName}
                </li>
                <div
                  className={`cursor-pointer ${
                    currProjectId === project._id ? "opacity-100" : "opacity-0"
                  }`}
                  onClick={() => {
                    handleDelete(project);
                  }}
                >
                  <RiDeleteBin6Line />
                </div>
              </div>
            ))}
        </ul>
      ) : (
        <h3>No Projects Yet</h3>
      )}
    </div>
  );
};

export default DesignHistory;
