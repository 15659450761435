import { color } from "d3";
import React, { useEffect, useState, useCallback, useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../userContext";
import Loader from "../Loader";
import { FaSignOutAlt } from "react-icons/fa";
import axios from "axios";

const LandingPage = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [startTouch, setStartTouch] = useState(0);

  const [user, setUser] = useContext(UserContext);
  const [loginClick, setLoginClick] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const MAX_SCROLL_POSITION = 1000; // Define max scroll position

  let backendURL;
  useEffect(() => {
    backendURL =
      process.env.REACT_APP_ENV === "production"
        ? process.env.REACT_APP_BACKEND
        : process.env.REACT_APP_BACKEND_LOCAL;
  });

  const handleLogin = () => {
    window.location.href = `${backendURL}/auth/google`;
    setLoginClick(true);
  };

  const handleProfileToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".dropdown")) {
        setIsOpen(false);
      }
      if (event.target.closest(".logout")) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleLogout = async () => {
    setLoginClick(true);
    setTimeout(() => {
      setLoginClick(false);
    }, 1000);
    setUser(null);
    try {
      const response = await axios.get(`${backendURL}/auth/logout`, {
        withCredentials: true, // Include credentials
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Throttle scroll updates for smoother animations
  const handleScroll = useCallback(() => {
    let ticking = false;

    return (event) => {
      const scrollAmount = event.deltaY;

      if (!ticking) {
        window.requestAnimationFrame(() => {
          setScrollPosition((prev) => {
            const nextPosition = prev + scrollAmount;
            return Math.max(0, Math.min(nextPosition, MAX_SCROLL_POSITION));
          });
          ticking = false;
        });
        ticking = true;
      }
    };
  }, [MAX_SCROLL_POSITION]);

  useEffect(() => {
    let mounted = false;

    const throttledScroll = handleScroll();

    const onScroll = (event) => {
      if (mounted) {
        throttledScroll(event);
      }
    };

    mounted = true; // Mark as mounted when the component is fully loaded

    window.addEventListener("wheel", onScroll);

    // Mobile touch scroll listeners
    window.addEventListener("touchstart", handleTouchStart);
    window.addEventListener("touchmove", handleTouchMove);

    return () => {
      mounted = false; // Cleanup to prevent the event from firing if the component is unmounted
      window.removeEventListener("wheel", onScroll);

      //for touch
      window.removeEventListener("touchstart", handleTouchStart);
      window.removeEventListener("touchmove", handleTouchMove);
    };
  }, [handleScroll]);

  const calculateOpacity2 = (minScroll, maxScroll) => {
    if (scrollPosition < minScroll) return 0;
    if (scrollPosition > maxScroll) return 1;
    return (scrollPosition - minScroll) / (maxScroll - minScroll);
  };
  const calculateOpacity = (minScroll, maxScroll) => {
    if (scrollPosition === 0) return 1;
    if (scrollPosition < 100) return 1;
    if (scrollPosition > 300) return 0.6 - scrollPosition / 1000;
  };

  useEffect(() => {
    const initialScrollPosition =
      window.scrollY || document.documentElement.scrollTop;
    setScrollPosition(initialScrollPosition);
  }, []);

  // for touch

  const handleTouchStart = (event) => {
    setStartTouch(event.touches[0].clientY);
  };

  const handleTouchMove = (event) => {
    const deltaY = startTouch - event.touches[0].clientY;
    setScrollPosition((prev) =>
      Math.max(0, Math.min(prev + deltaY, MAX_SCROLL_POSITION))
    );
  };

  return (
    <div className="landing-page overflow">
      <section className="relative h-screen bg-cover bg-center">
        <div
          className="main-background absolute inset-0 bg-cover"
          style={{
            // backgroundImage: `url('/background.jpg')`,
            opacity: 1 - scrollPosition / 1000, // Adjust opacity
            transition: "opacity 0.3s ease-out",
          }}
        ></div>

        <div
          className="header relative flex flex-row justify-between items-center p-3 h-24 w-4/5 md:w-3/5 md:p-6 lg:w-2/5 mx-auto top-20 z-50 "
          style={{
            opacity: calculateOpacity(0, 100),
            transition: "opacity 0.3s ease-out",
          }}
        >
          <h2
            className="poppins-bold-italic text-lg md:text-xl lg:text-3xl"
            style={{ text: "rgb(45, 45, 45)" }}
          >
            DesignersHangout
          </h2>
          {user ? (
            <div className="relative dropdown">
              <div
                onClick={() => {
                  handleProfileToggle();
                }}
                className="cursor-pointer flex items-center space-x-2"
              >
                <img
                  src={
                    user.profilePicture
                      ? user.profilePicture
                      : "https://www.gravatar.com/avatar/?d=mp"
                  }
                  className="w-16 h-16 rounded-full border-black border-2"
                  alt="User"
                />
              </div>
              {isOpen && (
                <div className="absolute top-20 right-10 md:left-10 w-56 bg-white shadow-lg rounded-md profile-dropdown">
                  <div className="flex flex-col items-center p-4">
                    <img
                      src={user.profilePicture}
                      alt="User"
                      className="w-16 h-16 rounded-full mb-2"
                    />
                    <span className="font-semibold text-gray-900">
                      {user.name}
                    </span>
                  </div>
                  <hr className="mt-2 border-gray-300" />
                  <span
                    className="logout flex flex-row items-center justify-center p-4 cursor-pointer hover:bg-gray-200"
                    onClick={() => handleLogout()}
                  >
                    <FaSignOutAlt className="mr-2" />
                    Logout
                  </span>
                </div>
              )}
            </div>
          ) : (
            <button
              className="w-16 py-3 font-semibold text-sm flex justify-center items-center md:w-28 md:text-lg"
              onClick={handleLogin}
            >
              {loginClick ? <Loader /> : "Login"}
            </button>
          )}
        </div>

        <div
          className={`absolute w-full mx-auto top-96 inset-0 flex justify-center text-center p-12`}
          style={{
            // transform: `translateY(-${Math.min(scrollPosition / 10, 200)}px)`,
            transform: `translateY(-${scrollPosition / 5}px)`,
            transition: "transform 0.3s ease-out",
          }}
        >
          {scrollPosition < 500 ? (
            <h1
              className="self-end text-6xl md:text-8xl lg:text-9xl font-my-font w-full"
              style={{
                text: "rgb(45, 45, 45)",
                opacity: calculateOpacity(0, 400),
                transition: "opacity 0.3s ease-out",
              }}
            >
              Take Control of Your Creativity with AI by Your Side
            </h1>
          ) : (
            <div className="flex flex-col gap-20">
              <div
                className="flex flex-col justify-center items-center"
                style={{
                  text: "rgb(45, 45, 45)",
                  opacity: calculateOpacity2(400, 800),
                  transition: "opacity 0.7s ease-in",
                }}
              >
                <p className="mt-16 text-xl md:text-2xl font-my-font w-2/3 md:w-4/5">
                  DesignersHangout isn’t about handing you a ready-made
                  solution. It’s about empowering you to own the creative
                  process. By integrating AI into the design journey, we provide
                  tools to enhance your workflow, while you stay in complete
                  control of the vision.
                </p>
                <p className="mt-16 text-xl md:text-2xl font-my-font w-2/3 md:w-4/5">
                  Let’s face it—asking AI to design something end-to-end often
                  results in generic, uninspired work. At DesignersHangout, we
                  believe design deserves more. Our tools are built to assist,
                  refine, and inspire—helping you stand out in a sea of
                  sameness, while keeping your originality at the forefront.
                </p>
              </div>
              <div className="cards">
                <div
                  className="flex flex-col justify-center items-center gap-14 mb-16"
                  style={{
                    opacity: calculateOpacity2(700, 900),

                    transition: "opacity 0.3s ease-in",
                  }}
                >
                  <div className="card flex flex-col justify-center md:justify-between items-center h-fit w-4/5 md:w-1/2 lg:w-1/3 p-5 gap-5">
                    <h2 className="text-3xl md:text-4xl font-semibold text-center">
                      Brainstorming
                    </h2>
                    <p className="text-center">
                      Jumpstart your creative process with Designers Hangout's
                      brainstorming feature! Get personalized color palette
                      recommendations, curated font pairings, or collaborate
                      with AI to refine and expand your project ideas.
                    </p>

                    <Link to="/brainstorming">
                      <button className="px-6 py-3 text-lg font-semibold text-white">
                        Explore
                      </button>
                    </Link>
                  </div>

                  <div className="card flex flex-col justify-center md:justify-between items-center h-fit w-4/5 md:w-1/2 lg:w-1/3 p-5 gap-5">
                    <h2 className="text-4xl font-semibold  text-center">
                      Design Critique
                    </h2>
                    <p className=" text-center">
                      Refine your designs with our Design Critique feature!
                      Receive detailed, principle-based feedback on layout,
                      color harmony, typography, and more. Get actionable
                      insights to improve iteratively and track your progress
                      with follow-up critiques, ensuring every iteration is a
                      step closer to perfection
                    </p>

                    <Link to="/design-critique">
                      <button className="px-6 py-3 text-lg font-semibold text-white">
                        Explore
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default LandingPage;
