import React, { useState, useEffect } from "react";
import axios from "axios";
import Loader from "../../Loader.js";
import { FaAngleDoubleRight } from "react-icons/fa";

const FontPairingsForm = ({ setFontPairings, setSubmitted }) => {
  const [description, setDescription] = useState("");
  const [submit, setSubmit] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [error, isError] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  let backendURL;
  useEffect(() => {
    backendURL =
      process.env.REACT_APP_ENV === "production"
        ? process.env.REACT_APP_BACKEND
        : process.env.REACT_APP_BACKEND_LOCAL;
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmit(true);
    setIsButtonDisabled(true);
    try {
      const response = await axios.post(`${backendURL}/generate-fonts`, {
        description,
      });
      setFontPairings(response.data.fonts);
      setSubmitted(true);
      setSubmit(false);
    } catch (error) {
      console.error("Error fetching font pairings:", error);
    }

    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
  };

  const handleBlur = () => {
    if (description.length < 10) {
      setErrorMsg("Description must be at least 10 characters.");
    } else if (description.length > 100) {
      setErrorMsg("Description cannot exceed 100 characters.");
    } else {
      const regex = /^[a-zA-Z0-9 .,!?'"()-]*$/;
      if (!regex.test(description)) {
        setErrorMsg("Description contains invalid characters.");
      } else {
        setErrorMsg("");
      }
    }
  };
  useEffect(() => {
    if (description.length < 10) {
      isError(true);
    } else if (description.length > 100) {
      isError(true);
    } else {
      const regex = /^[a-zA-Z0-9 .,!?'"()-]*$/;
      if (!regex.test(description)) {
        isError(true);
      } else {
        setErrorMsg("");
      }
    }
  }, [description]);

  useEffect(() => {
    setIsButtonDisabled(errorMsg !== "" || description === "");
  }, [errorMsg, description]);

  return (
    <form
      onSubmit={handleSubmit}
      className="card p-10 rounded-lg h-64   shadow-lg mb-8 mx-auto max-w-lg  flex flex-col justify-center items-center"
    >
      <label
        htmlFor="description"
        className="text-2xl font-bold h-1/2 flex justify-center items-center"
      >
        <span className="poppins-bold">What are you working on today?</span>
      </label>
      <div className="w-full h-1/2 flex flex-row items-center justify-center">
        <input
          type="text"
          id="description"
          value={description}
          placeholder="Playful fonts for a kids’ book cover"
          onChange={(e) => setDescription(e.target.value)}
          required
          onBlur={handleBlur}
          className="block w-2/3 border border-gray-300 rounded-md p-3"
        />
        <button
          type="submit"
          className={`submit-button w-12 h-12 text-white font-semibold rounded-md flex justify-center items-center p-0 ${
            isButtonDisabled
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-blue-500 cursor-pointer"
          }`}
          disabled={isButtonDisabled}
        >
          {submit ? <Loader /> : <FaAngleDoubleRight className="text-lg" />}
        </button>
      </div>
      {errorMsg && <span className="text-red-500">{errorMsg}</span>}
    </form>
  );
};

export default FontPairingsForm;
