const CollectionHistory = ({ collections, setColorPalettes, setSubmitted }) => {
  const loadProject = (collection) => {
    const colors = {};
    collection.colorPalettes.map((palette, index) => {
      colors[index + 1] = palette.colorValues[0];
    });
    const data = {
      colors: colors,
    };
    setColorPalettes(data);
    setSubmitted(true);
  };
  return (
    <div className="mt-6 rest-card rounded-lg shadow-lg p-8 mx-auto max-w-lg">
      <h2 className="text-xl font-bold text-gray-900 mb-4 poppins-bold">
        Collections
      </h2>
      {collections.length > 0 ? (
        <ul className="space-y-2 poppins-regular">
          {collections &&
            collections.length > 0 &&
            collections.map((collection, index) => (
              <li
                key={index}
                className="cursor-pointer text-indigo-600 hover:underline"
                onClick={() => loadProject(collection)}
              >
                {collection.name}
              </li>
            ))}
        </ul>
      ) : (
        <h3>No Collections</h3>
      )}
    </div>
  );
};

export default CollectionHistory;
