import React, { useState, useEffect } from "react";
import ThreeDotsLoader from "./ThreeDotsLoader";

const DesignForm = ({
  setSubmitted,
  SetDataFromBackend,
  setCurrentIndex,
  setButtons,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [projectName, setProjectName] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [nameError, setNameError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  let backendURL;
  useEffect(() => {
    backendURL =
      process.env.REACT_APP_ENV === "production"
        ? process.env.REACT_APP_BACKEND
        : process.env.REACT_APP_BACKEND_LOCAL;
  });

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check file type
      const validImageTypes = ["image/jpeg", "image/png", "image/gif"];
      if (!validImageTypes.includes(file.type)) {
        alert("Please upload a valid image file (JPEG or PNG)");
        event.target.value = ""; // Clear the input
        return;
      }
      setSelectedFile(event.target.files[0]);
    }
  };

  const onSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    setIsButtonDisabled(true);

    if (!selectedFile) {
      console.error("Please select a design file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("projectName", projectName);
    formData.append("description", description);
    formData.append("design", selectedFile);

    try {
      const response = await fetch(`${backendURL}/critique`, {
        credentials: "include",
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Error uploading design: ${response.statusText}`);
      }

      const data = await response.json();
      setLoading(false);
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 3000);

      try {
        SetDataFromBackend(data.critique);
      } catch (error) {
        console.log(error);
      }

      setProjectName("");
      setDescription("");
      setSelectedFile(null);
      setSubmitted(true);
      setCurrentIndex(0);
      setButtons({
        previous: true,
        next: true,
        upload: false,
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const projectNameBlur = () => {
    if (projectName.length > 20) {
      setNameError("Project Name cannot exceed 20 characters");
    } else {
      const regex = /^[a-zA-Z0-9 .,!?'"()-]*$/;
      if (!regex.test(projectName)) {
        setNameError("Project Name contains invalid characters.");
      } else {
        setNameError("");
      }
    }
  };

  const handleBlur = () => {
    if (description.length < 10) {
      setDescriptionError("Description must be at least 10 characters.");
    } else if (description.length > 500) {
      setDescriptionError("Description cannot exceed 500 characters.");
    } else {
      const regex = /^[a-zA-Z0-9 .,!?'"()-]*$/;
      if (!regex.test(projectName)) {
        setNameError("Description contains invalid characters.");
      } else {
        setDescriptionError("");
      }
    }
  };

  useEffect(() => {
    projectNameBlur();
  }, [projectName, description]);

  useEffect(() => {
    setIsButtonDisabled(
      nameError !== "" ||
        descriptionError !== "" ||
        projectName === "" ||
        description === "" ||
        !selectedFile
    );
  });

  return (
    <div className="">
      <form
        className="relative rest-card p-8 rounded-lg shadow-lg mb-8 mx-auto max-w-lg space-y-6"
        onSubmit={(e) => onSubmit(e)}
      >
        <div>
          <label
            htmlFor="projectName"
            className="block text-lg mb-4 poppins-bold"
          >
            Project Name
          </label>
          <input
            type="text"
            id="projectName"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
            onBlur={projectNameBlur}
            required
            className="block w-full border border-gray-300 rounded-md p-3 mb-4"
          />
          {nameError && <span className="text-red-500">{nameError}</span>}
        </div>
        <div>
          <label
            htmlFor="description"
            className="block text-lg poppins-bold mb-4"
          >
            Description
          </label>
          <textarea
            id="description"
            rows="4"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            onBlur={handleBlur}
            required
            className="block w-full border border-gray-300 rounded-md p-3 mb-4"
          />
          {descriptionError && (
            <span className="text-red-500">{descriptionError}</span>
          )}
        </div>
        <div>
          <label
            htmlFor="designUpload"
            className="block text-lg poppins-bold mb-4"
          >
            Upload Design
          </label>
          <input
            type="file"
            accept="image/*"
            id="designUpload"
            onChange={handleFileUpload}
            required
            className="block w-full border border-gray-300 rounded-md p-3 mb-4 bg-gray-100 poppins-regular"
          />
        </div>
        <button
          type="submit"
          className={`w-full  px-6 py-3 text-white  rounded-md shadow-sm poppins-bold ${
            isButtonDisabled ? "cursor-not-allowed" : "cursor-pointer"
          }`}
          disabled={isButtonDisabled}
        >
          {loading ? <ThreeDotsLoader /> : "Submit"}
        </button>
      </form>
    </div>
  );
};

export default DesignForm;
