// CircularProgressBar.js
import React, { useEffect, useState } from "react";
import "./CircularProgressBar.css";

const CircularProgressBar = ({ progress, size = 100, strokeWidth = 10 }) => {
  const [color, setColor] = useState("#00FF48");
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (progress / 100) * circumference;
  useEffect(() => {
    if (progress >= 75) {
      setColor("#6D9F71");
    } else if (progress < 75 && progress >= 40) {
      setColor("#D1B26F");
    } else {
      setColor("#C55A5A");
    }
  }, [progress]);

  //yellow #D1B26F
  //green #6D9F71
  //red #C55A5A
  return (
    <svg width={size} height={size} className="circular-progress-bar">
      <circle
        className="circular-progress-background"
        stroke={color}
        fill="none"
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={strokeWidth}
      />
      <circle
        className="circular-progress-circle"
        stroke={color}
        fill="none"
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={offset}
      />
      <text
        x="50%"
        y="50%"
        dy=".3em"
        textAnchor="middle"
        className="circular-progress-text"
        style={{
          fill: `${color}`
        }}
      >
        {progress}
      </text>
    </svg>
  );
};

export default CircularProgressBar;
